<template>
  <div class="d-flex align-items-center bg-white text-light border-top border-top-2 border-primary vh-100">
    <div class="container">
      <div class="row align-items-center justify-content-center">
        <div class="col-12 col-md-5 col-xl-4 order-md-1 mb-5">
          <div class="text-center mb-5">
            <img src="@/assets/img/login_logo.png" alt="..." class="" height="80">
            <div class="text-center mt-3">
              <small class="text-black font-weight-bold">
                LEBURMA | CMS
              </small>
            </div>
          </div>
          <form>
            <TextInput label="Usuário" :model="v$.form.email" type="text" class="text-white"
              placeholder="Informe o nome de usuário" />

            <PasswordInput label="Senha" :model="v$.form.password" class="text-white" placeholder="Informe sua senha" />
            <div class="text-danger text-center">
              <small>{{ errorMessage }}</small>
            </div>
            <FormButton label="Entrar" :loading="submitting" :disabled="v$.$invalid" class="mb-3" @onClick="login" />
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import LoginController from './LoginController'
export default LoginController
</script>
