import ListControllerMixin from '@/mixins/ListControllerMixin'
import moment from 'moment'
import { defineComponent } from 'vue'

export default defineComponent({
    mixins: [ListControllerMixin],
    // mounted() {
    //     this.loadingItems = true
    //     this.getData()
    // },
    data() {
        return {
            columns: [
                {
                    name: 'Nome',
                    sortName: null,
                    order: null
                },
                {
                    name: 'Cargo',
                    sortName: null,
                    order: null
                },
                {
                    name: 'Criado em',
                    sortName: null,
                    order: null
                }
            ],
            workingSites: [],
            selectedAdmin: ''
        }
    },
    validations: {
        selectedAdmin: ''
    },
    methods: {
        prepareResponse(data: any) {
            data.map((elem: any) => {
                elem = this.$membersService.getFormattedMember(elem)
            })
            return data
        },
        fetchPromise() {
            return this.$membersService.list({
                ...this.filters,
                orderBy: this.columns.filter(r => r.order != null && r.sortName != null)
            })
        },
    }
})