import { store } from '@/store'
import Home from '@/views/Home/Home.vue'
import Projects from '@/views/Projects/Projects.vue'
import ProjectDetail from '@/views/Projects/ProjectDetail/ProjectDetail.vue'
import ProjectAdd from '@/views/Projects/ProjectAdd/ProjectAdd.vue'
import Members from '@/views/Members/Members.vue'
import MemberDetail from '@/views/Members/MemberDetail/MemberDetail.vue'
import MemberAdd from '@/views/Members/MemberAdd/MemberAdd.vue'
import Login from '@/views/Login/Login.vue'
import NotFound from '@/views/NotFound/NotFound.vue'
import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import paths from './paths'
import AdminDetail from '@/views/Admins/AdminDetail/AdminDetail.vue'
import Admins from '@/views/Admins/Admins.vue'
import AdminsAdd from '@/views/Admins/AddAdmins/AddAdmins.vue'
import AdminPasswordUpdate from '@/views/Admins/AdminPasswordUpdate/AdminPasswordUpdate.vue'

const routes: Array<RouteRecordRaw> = [

  {
    path: paths.login,
    name: 'Login',
    component: Login,
    props: true,
  },
  {
    path: paths.projects.list,
    name: 'Projects',
    alias: '/',
    component: Projects,
    meta: { requiresAuth: true }
  },
  {
    path: `${paths.projects.detail}/:id`,
    name: 'ProjectDetail',
    props: true,
    component: ProjectDetail,
    meta: { requiresAuth: true }
  },
  {
    path: `${paths.projects.add}`,
    name: 'ProjectAdd',
    props: true,
    component: ProjectAdd,
    meta: { requiresAuth: true }
  },
  {
    path: `${paths.projects.edit}/:id`,
    name: 'ProjectEdit',
    props: true,
    component: ProjectAdd,
    meta: { requiresAuth: true }
  },
  // {
  //   path: paths.home,
  //   name: 'Home',
  //   component: Home,
  //   meta: { requiresAuth: true }
  // },
  {
    path: paths.admins.list,
    name: 'Admins',
    component: Admins,
    props: true,
  },
  {
    path: `${paths.admins.detail}/:id`,
    name: 'AdminDetail',
    component: AdminDetail,
    props: true,
    meta: { requiresAuth: true }
  },
  {
    path: paths.admins.add,
    name: 'AdminAdd',
    component: AdminsAdd,
    meta: { requiresAuth: true }
  },
  {
    path: `${paths.admins.edit}/:id`,
    name: 'AdminsAdd',
    props: true,
    component: AdminsAdd,
    meta: { requiresAuth: true }
  },
  {
    path: `${paths.admins.password_update}/:id`,
    name: 'AdminsPasswordUpdate',
    props: true,
    component: AdminPasswordUpdate,
    meta: { requiresAuth: true }
  },

  {
    path: paths.members.list,
    name: 'Members',
    component: Members,
    meta: { requiresAuth: true }
  },
  {
    path: `${paths.members.detail}/:id`,
    name: 'MemberDetail',
    props: true,
    component: MemberDetail,
    meta: { requiresAuth: true }
  },
  {
    path: `${paths.members.edit}/:id`,
    name: 'MemberEdit',
    props: true,
    component: MemberAdd,
    meta: { requiresAuth: true }
  },
  {
    path: `${paths.members.add}`,
    name: 'MemberAdd',
    props: true,
    component: MemberAdd,
    meta: { requiresAuth: true }
  },
  {
    path: paths.not_found,
    name: 'NotFound',
    component: NotFound
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: {
      name: 'NotFound'
    }
  },

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  const isLoggedIn = !!store.state.session;
  if (to.meta.requiresAuth && !isLoggedIn) {
    next(paths.login);
  } else {
    next();
  }
});

export default router
