import { fakeResponse } from '@/fake_api'
import IMember from '@/interfaces/IMember'
import moment from 'moment'
import NetworkService, { Endpoint } from './NetworkService'

export default class MembersService {
  network
  constructor() {
    this.network = new NetworkService()
  }
  create = (params: IMember) => {
    return this.network.postMultipart(Endpoint.members, params)
  }
  list = (params: any) => {
    return this.network.get(Endpoint.members, params)
  }
  show = (id: string) => {
    return this.network.get(`${Endpoint.members}/${id}`)
  }
  update = (id: string, params: IMember) => {
    return this.network.postMultipart(`${Endpoint.members}/${id}/update`, params)
  }
  delete = (id: string) => {
    return this.network.delete(`${Endpoint.members}/${id}`)
  }
  getFormattedMember = (data: any) => {
    data.formatted_created_at = moment(data.created_at).format('DD/MM/YYYY')
    data.formatted_date = moment(data.date).format('DD/MM/YYYY')
    data.formatted_profile_picture = `${process.env.VUE_APP_IMG_BASE_URL}${data.profile_picture}`
    data.formatted_projects = data.projects ? data.projects.map((r: any) => {
      return {
        id: r.project.id,
        title: r.project.title,
        formatted_cover_image: `${process.env.VUE_APP_IMG_BASE_URL}${r.project.cover_image}`,
      }
    }) : []
    return data
  }

}
