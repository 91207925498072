export interface IProjectImage {
  id: number | string,
  image?: File,
  order: number,
  url?: string,
  description: string,
  type: ImageOrientation,
}
export enum ImageOrientation {
  PORTRAIT = 'portrait',
  LANDSCAPE = 'landscape'
}
