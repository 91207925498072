import { fakeCategories, fakeProjectShow, fakeProjects } from '@/fake_api'
import NetworkService, { Endpoint } from './NetworkService'
import moment from 'moment'
import IProject from '@/interfaces/IProject'
import { store } from '@/store'

export default class ProjectsService {
  network
  constructor() {
    this.network = new NetworkService()
  }
  create = (params: IProject) => {
    return this.network.postMultipart(Endpoint.projects, params)
  }
  list = (params: any) => {
    return this.network.get(Endpoint.projects, params)
  }
  show = (id: string) => {
    return this.network.get(`${Endpoint.projects}/${id}`)
  }
  update = (id: string, params: IProject) => {
    return this.network.postMultipart(`${Endpoint.projects}/${id}/update`, params)
  }
  delete = (id: string) => {
    return this.network.delete(`${Endpoint.projects}/${id}`)
  }
  listCategories = () => {
    return Promise.resolve(fakeCategories)
    // return this.network.post(`${}`, params)
  }

  getFormattedProject(data: any) {
    data.formatted_created_at = moment(data.created_at).format("DD/MM/YYYY")
    data.formatted_date = moment(data.date).format('DD/MM/YYYY')
    data.formatted_cover_image = `${process.env.VUE_APP_IMG_BASE_URL}${data.cover_image}`
    data.formatted_featured = data.featured ? 'Sim' : 'Não'
    data.formatted_coords = { lat: Number(data.lat), lng: Number(data.lng) }
    data.images.map((elem: any) => {
      elem.formatted_path = `${process.env.VUE_APP_IMG_BASE_URL}${elem.path}`
    })
    data.images.sort((a: any, b: any) => {
      if (a.order > b.order) {
        return 1
      }
      if (a.order < b.order) {
        return -1
      }
      return 0
    })

    console.log(data.images)

    data.members = data.members.filter((m: any) => m.member_info != null).map((elem: any) => {
      elem.member_info.formatted_profile_picture = `${process.env.VUE_APP_IMG_BASE_URL}${elem.member_info.profile_picture}`
      // return {
      //   ...elem,
      //   member_info: {
      //     ...elem.member_info,
      //     formatted_profile_picture: `${process.env.VUE_APP_IMG_BASE_URL}${elem.member_info.profile_picture}`
      //   }
      // }
      return elem
    })


    return data
  }

}
