<template>
  <div id="mission_detail">
    <Navbar current="Detalhes do membro" :link="$paths.projects.list" :linkName="'Membro'" />
    <Sidebar />
    <Container>
      <Header title="Detalhes do membro">
        <template v-slot:button>
          <button v-if="hasDelete" @click="handleDelete" class="btn btn-outline-primary ms-3">
            Remover
            <i class="fa-solid fa-trash-alt me-1"></i>
          </button>
          <router-link :to="`${$paths.members.edit}/${id}`" tag="a" class="btn btn-primary lift ms-3">
            Editar
            <i class="fa-solid fa-pen me-1"></i>
          </router-link>
        </template>
      </Header>
      <LoadingContainer v-if="loadingItems" />
      <div v-else>
        <div class="row">
          <div class="col-12 col-xl-12">
            <div class="card">
              <div class="card-body">
                <div class="row align-items-end">
                  <div class="col-auto">
                    <div class="avatar avatar-xxl header-avatar-top">
                      <img class="avatar-img rounded-circle border border-4 border-body"
                        :src="item.formatted_profile_picture" />
                    </div>
                  </div>
                  <div class="col mb-3 ms-n3 ms-md-n2">
                    <h6 class="header-pretitle">{{ item.position }}</h6>
                    <h1 class="header-title">{{ item.name }}</h1>
                  </div>
                </div>
                <hr>
                <div class="row">
                  <h3 class="mt-5">Projetos</h3>
                  <div class="col-12">
                    <div class="list-group list-group-flush my-n3">
                      <div v-for="(project, index) in item.formatted_projects" class="list-group-item">
                        <router-link v-slot="{ navigate }" :to="`${$paths.projects.detail}/${project.id}`">
                          <div class="row align-items-center">
                            <div class="col-auto">
                              <div class="cover-img rounded">
                                <img :src="project.formatted_cover_image" alt="..." class="avatar-img rounded">
                              </div>
                            </div>
                            <div class="col ms-n2">
                              <h4 class="mb-1">
                                <span>{{ project.title }}</span>
                              </h4>
                            </div>
                          </div>
                        </router-link>
                      </div>
                    </div>
                  </div>
                </div>
                <hr>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Container>
  </div>
</template>
<script>
import MemberDetailController from "./MemberDetailController"
export default MemberDetailController
</script>
<style scoped>
.profile-img {
  height: 100px;
  object-fit: cover;
}

.cover-img {
  height: 60px;
  width: 90px;
}
</style>
