import ConfirmationAlert from '@/components/ConfirmationAlert/ConfirmationAlert.vue'
import Container from '@/components/Container.vue'
import EmptyState from '@/components/EmptyState.vue'
import EditorInput from '@/components/FormComponents/EditorInput.vue'
import FormButton from '@/components/FormComponents/FormButton.vue'
import PasswordInput from '@/components/FormComponents/PasswordInput.vue'
import SelectInput from '@/components/FormComponents/SelectInput.vue'
import TextAreaInput from '@/components/FormComponents/TextAreaInput.vue'
import TextInput from '@/components/FormComponents/TextInput.vue'
import ImageCropper from './components/ImageCropper/ImageCropper.vue'
import Header from '@/components/Header.vue'
import HomeCard from '@/components/HomeCard.vue'
import LoadingContainer from '@/components/LoadingContainer.vue'
import ModalComponent from '@/components/ModalComponent.vue'
import Navbar from '@/components/Navbar.vue'
import SearchBar from '@/components/SearchBar.vue'
import Sidebar from '@/components/Sidebar.vue'
import TableHead from '@/components/TableHead.vue'
import '@fortawesome/fontawesome-free/css/all.css'
import '@popperjs/core'
import 'bootstrap'
import 'jquery'
import { createApp } from 'vue'
import App from './App.vue'
import './assets/theme/scss/theme.scss'
import './assets/css/styles.scss'
import router from './router'
import paths from './router/paths'
import VueGoogleMaps from '@fawmi/vue-google-maps'
import { EventBus } from './utils/EventBus'
import './utils/Prototypes/prototypeFunctions'
import UserService from './services/UserService'
import { store } from './store'
import ProjectsService from './services/ProjectsService'
import MembersService from './services/MembersService'

const app = createApp(App)
app.config.globalProperties.$eventBus = EventBus
app.config.globalProperties.$userService = new UserService()
app.config.globalProperties.$projectsService = new ProjectsService()
app.config.globalProperties.$membersService = new MembersService()
app.config.globalProperties.$paths = paths

app.component('Sidebar', Sidebar)
app.component('Navbar', Navbar)
app.component('LoadingContainer', LoadingContainer)
app.component('EmptyState', EmptyState)
app.component('Container', Container)
app.component('SearchBar', SearchBar)
app.component('TextInput', TextInput)
app.component('SelectInput', SelectInput)
app.component('TextAreaInput', TextAreaInput)
app.component('PasswordInput', PasswordInput)
app.component('EditorInput', EditorInput)
app.component('FormButton', FormButton)
app.component('TableHead', TableHead)
app.component('ModalComponent', ModalComponent)
app.component('Header', Header)
app.component('ConfirmationAlert', ConfirmationAlert)
app.component('HomeCard', HomeCard)
app.component('ImageCropper', ImageCropper)
app.config.globalProperties.$paths = paths

app
.use(store)
.use(router)
.use(VueGoogleMaps, {
    load: {
        key: process.env.VUE_APP_GOOGLE_API_KEY,
        libraries: "places"
    },
})
.mount('#app')
