export const fakeLogin = {
  status: true,
  data: {
    name: 'Api coom erro',
    email: 'andrews.alves92@gmail.com',
    type: 'admin',
    created_at: '2023-08-01 00:00:00'
  }
}
export const fakeCategories = {
  "status": true,
  "data": [
    "Residencial",
    "Corporativo",
    "Incorporação"
  ]
}
export const fakeResponse = {
  status: true,
  message: 'Erro ao efetuar isso ae',
  data: {}
}
export const fakeProjectShow = {
  status: true,
  data: {
    "id": 11,
    "title": "Resultado de pesquisa",
    "category": "Residencial",
    "description": "Liquidificador",
    "cover_image": "projects\/11\/cover_image\/d322a9101f21984c347d5c0349606f3d.png",
    "date": "2023-01-01",
    "footage": "32",
    "location": "Rua de teste 123",
    "lat": "-1234567",
    "lng": "-3056789",
    "featured": true,
    "created_at": "2023-08-01T14:46:31.000000Z",
    "updated_at": "2023-08-01T14:46:33.000000Z",
    "members": [
      {
        "id": 9,
        "project_id": 11,
        "team_member_id": 1,
        "created_at": "2023-08-01T14:46:35.000000Z",
        "updated_at": "2023-08-01T14:46:35.000000Z",
        "member_info": {
          "id": 1,
          "profile_picture": "team_members\/1b5becebc4430e32db20d8fc0662013e8.png",
          "position": "Faxineiro Pleno",
          "name": "Pimpolho Rodrigo",
          "created_at": "2023-08-01T14:27:22.000000Z",
          "updated_at": "2023-08-01T14:27:23.000000Z"
        }
      },
      {
        "id": 10,
        "project_id": 11,
        "team_member_id": 2,
        "created_at": "2023-08-01T14:46:35.000000Z",
        "updated_at": "2023-08-01T14:46:35.000000Z",
        "member_info": {
          "id": 2,
          "profile_picture": "team_members\/26e558c4a90ba9b2b1356722c32c7ebfd.png",
          "position": "Removedor de Vinagrete de Hotdogs",
          "name": "Rafaelson Martinelson",
          "created_at": "2023-08-01T14:28:24.000000Z",
          "updated_at": "2023-08-01T14:28:25.000000Z"
        }
      }
    ],
    "images": [
      {
        "id": 17,
        "project_id": 11,
        "path": "projects\/11\/images\/12b134bb909dac24f70eadd9a00dd9b1.png",
        "description": "descricao imagem 1",
        "order": 0,
        "type": "landscape",
        "created_at": "2023-08-01T14:46:33.000000Z",
        "updated_at": "2023-08-01T14:46:34.000000Z"
      },
      {
        "id": 18,
        "project_id": 11,
        "path": "projects\/11\/images\/3806cdf12a0425b7c402c45ada729690.png",
        "description": "descricao imagem 2",
        "order": 1,
        "type": "portrait",
        "created_at": "2023-08-01T14:46:34.000000Z",
        "updated_at": "2023-08-01T14:46:34.000000Z"
      }
    ]
  }
}

export const fakeStatistics = {
  status: true,
  data: {
    projects: 90,
    members: 35
  }
}
export const fakeMemberShow = {
  status: true,
  data: {
    "id": 8,
    "project_id": 10,
    "team_member_id": 2,
    "created_at": "2023-08-01T14:45:13.000000Z",
    "updated_at": "2023-08-01T14:45:13.000000Z",
    "profile_picture": "team_members\/26e558c4a90ba9b2b1356722c32c7ebfd.png",
    "position": "Removedor de Vinagrete de Hotdogs",
    "name": "Rafaelson Martinelson",
    projects: [
      {
        "id": 11,
        "title": "Resultado de pesquisa",
        "category": "Residencial",
        "description": "Liquidificador",
        "cover_image": "projects\/11\/cover_image\/d322a9101f21984c347d5c0349606f3d.png",
        "date": "2023-01-01",
        "footage": "32",
        "location": "Rua de teste 123",
        "lat": "-1234567",
        "lng": "-3056789",
        "featured": false,
        "created_at": "2023-08-01T14:46:31.000000Z",
        "updated_at": "2023-08-01T14:46:33.000000Z",
      },
      {
        "id": 12,
        "title": "Resultado de pesquisa",
        "category": "Residencial",
        "description": "Liquidificador",
        "cover_image": "projects\/11\/cover_image\/d322a9101f21984c347d5c0349606f3d.png",
        "date": "2023-01-01",
        "footage": "32",
        "location": "Rua de teste 123",
        "lat": "-1234567",
        "lng": "-3056789",
        "featured": false,
        "created_at": "2023-08-01T14:46:31.000000Z",
        "updated_at": "2023-08-01T14:46:33.000000Z",
      },
      {
        "id": 13,
        "title": "Resultado de pesquisa",
        "category": "Residencial",
        "description": "Liquidificador",
        "cover_image": "projects\/11\/cover_image\/d322a9101f21984c347d5c0349606f3d.png",
        "date": "2023-01-01",
        "footage": "32",
        "location": "Rua de teste 123",
        "lat": "-1234567",
        "lng": "-3056789",
        "featured": false,
        "created_at": "2023-08-01T14:46:31.000000Z",
        "updated_at": "2023-08-01T14:46:33.000000Z",
      },
    ]
  }
}

export const fakeMembers = {
  "current_page": 1,
  "data": [
    {
      "id": 9,
      "project_id": 11,
      "team_member_id": 1,
      "created_at": "2023-08-01T14:46:35.000000Z",
      "updated_at": "2023-08-01T14:46:35.000000Z",
      "profile_picture": "team_members\/1b5becebc4430e32db20d8fc0662013e8.png",
      "position": "Faxineiro Pleno",
      "name": "Pimpolho Rodrigo",
    },
    {
      "id": 10,
      "project_id": 11,
      "team_member_id": 2,
      "created_at": "2023-08-01T14:46:35.000000Z",
      "updated_at": "2023-08-01T14:46:35.000000Z",
      "profile_picture": "team_members\/26e558c4a90ba9b2b1356722c32c7ebfd.png",
      "position": "Removedor de Vinagrete de Hotdogs",
      "name": "Rafaelson Martinelson",
    },
    {
      "id": 7,
      "project_id": 10,
      "team_member_id": 1,
      "created_at": "2023-08-01T14:45:13.000000Z",
      "updated_at": "2023-08-01T14:45:13.000000Z",
      "profile_picture": "team_members\/1b5becebc4430e32db20d8fc0662013e8.png",
      "position": "Faxineiro Pleno",
      "name": "Pimpolho Rodrigo",
    },
    {
      "id": 8,
      "project_id": 10,
      "team_member_id": 2,
      "created_at": "2023-08-01T14:45:13.000000Z",
      "updated_at": "2023-08-01T14:45:13.000000Z",
      "profile_picture": "team_members\/26e558c4a90ba9b2b1356722c32c7ebfd.png",
      "position": "Removedor de Vinagrete de Hotdogs",
      "name": "Rafaelson Martinelson",
    },
    {
      "id": 1,
      "project_id": 7,
      "team_member_id": 1,
      "created_at": "2023-08-01T14:35:11.000000Z",
      "updated_at": "2023-08-01T14:35:11.000000Z",
      "profile_picture": "team_members\/1b5becebc4430e32db20d8fc0662013e8.png",
      "position": "Faxineiro Pleno",
      "name": "Pimpolho Rodrigo",
    },
    {
      "id": 2,
      "project_id": 7,
      "team_member_id": 2,
      "created_at": "2023-08-01T14:35:11.000000Z",
      "updated_at": "2023-08-01T14:35:11.000000Z",
      "profile_picture": "team_members\/26e558c4a90ba9b2b1356722c32c7ebfd.png",
      "position": "Removedor de Vinagrete de Hotdogs",
      "name": "Rafaelson Martinelson",
    }
  ],
  "next_page_url": null,
  "path": "http:\/\/leburma-api-dev.phurshell.com\/api\/projects",
  "per_page": 100,
  "prev_page_url": null,
  "to": 7,
  "total": 7,
  "status": true
}
export const fakeProjects = {
  "current_page": 1,
  "data": [
    {
      "id": 11,
      "title": "Resultado de pesquisa",
      "category": "Residencial",
      "description": "Liquidificador",
      "cover_image": "projects\/11\/cover_image\/d322a9101f21984c347d5c0349606f3d.png",
      "date": "2023-01-01",
      "footage": "32",
      "location": "Rua de teste 123",
      "lat": "-1234567",
      "lng": "-3056789",
      "featured": false,
      "created_at": "2023-08-01T14:46:31.000000Z",
      "updated_at": "2023-08-01T14:46:33.000000Z",
      "members": [
        {
          "id": 9,
          "project_id": 11,
          "team_member_id": 1,
          "created_at": "2023-08-01T14:46:35.000000Z",
          "updated_at": "2023-08-01T14:46:35.000000Z",
          "member_info": {
            "id": 1,
            "profile_picture": "team_members\/1b5becebc4430e32db20d8fc0662013e8.png",
            "position": "Faxineiro Pleno",
            "name": "Pimpolho Rodrigo",
            "created_at": "2023-08-01T14:27:22.000000Z",
            "updated_at": "2023-08-01T14:27:23.000000Z"
          }
        },
        {
          "id": 10,
          "project_id": 11,
          "team_member_id": 2,
          "created_at": "2023-08-01T14:46:35.000000Z",
          "updated_at": "2023-08-01T14:46:35.000000Z",
          "member_info": {
            "id": 2,
            "profile_picture": "team_members\/26e558c4a90ba9b2b1356722c32c7ebfd.png",
            "position": "Removedor de Vinagrete de Hotdogs",
            "name": "Rafaelson Martinelson",
            "created_at": "2023-08-01T14:28:24.000000Z",
            "updated_at": "2023-08-01T14:28:25.000000Z"
          }
        }
      ],
      "images": [
        {
          "id": 17,
          "project_id": 11,
          "path": "projects\/11\/images\/12b134bb909dac24f70eadd9a00dd9b1.png",
          "description": "descricao imagem 1",
          "order": 0,
          "type": "landscape",
          "created_at": "2023-08-01T14:46:33.000000Z",
          "updated_at": "2023-08-01T14:46:34.000000Z"
        },
        {
          "id": 18,
          "project_id": 11,
          "path": "projects\/11\/images\/3806cdf12a0425b7c402c45ada729690.png",
          "description": "descricao imagem 2",
          "order": 1,
          "type": "portrait",
          "created_at": "2023-08-01T14:46:34.000000Z",
          "updated_at": "2023-08-01T14:46:34.000000Z"
        }
      ]
    },
    {
      "id": 10,
      "title": "Resultado de pesquisa",
      "category": "Residencial",
      "description": "Liquidificador",
      "cover_image": "projects\/10\/cover_image\/3e6de0a770f419f17720a87e219ec1fd.png",
      "date": "2023-01-01",
      "footage": "32",
      "location": "Rua de teste 123",
      "lat": "-1234567",
      "lng": "-3056789",
      "featured": false,
      "created_at": "2023-08-01T14:45:09.000000Z",
      "updated_at": "2023-08-01T14:45:10.000000Z",
      "members": [
        {
          "id": 7,
          "project_id": 10,
          "team_member_id": 1,
          "created_at": "2023-08-01T14:45:13.000000Z",
          "updated_at": "2023-08-01T14:45:13.000000Z",
          "member_info": {
            "id": 1,
            "profile_picture": "team_members\/1b5becebc4430e32db20d8fc0662013e8.png",
            "position": "Faxineiro Pleno",
            "name": "Pimpolho Rodrigo",
            "created_at": "2023-08-01T14:27:22.000000Z",
            "updated_at": "2023-08-01T14:27:23.000000Z"
          }
        },
        {
          "id": 8,
          "project_id": 10,
          "team_member_id": 2,
          "created_at": "2023-08-01T14:45:13.000000Z",
          "updated_at": "2023-08-01T14:45:13.000000Z",
          "member_info": {
            "id": 2,
            "profile_picture": "team_members\/26e558c4a90ba9b2b1356722c32c7ebfd.png",
            "position": "Removedor de Vinagrete de Hotdogs",
            "name": "Rafaelson Martinelson",
            "created_at": "2023-08-01T14:28:24.000000Z",
            "updated_at": "2023-08-01T14:28:25.000000Z"
          }
        }
      ],
      "images": [
        {
          "id": 15,
          "project_id": 10,
          "path": "projects\/10\/images\/73e9db2f07fd9ed8da890abf80e01b2c.png",
          "description": "descricao imagem 1",
          "order": 0,
          "type": "landscape",
          "created_at": "2023-08-01T14:45:11.000000Z",
          "updated_at": "2023-08-01T14:45:11.000000Z"
        },
        {
          "id": 16,
          "project_id": 10,
          "path": "projects\/10\/images\/6e33624fc938878ec1fb72ab916c6991.png",
          "description": "descricao imagem 2",
          "order": 1,
          "type": "portrait",
          "created_at": "2023-08-01T14:45:12.000000Z",
          "updated_at": "2023-08-01T14:45:12.000000Z"
        }
      ]
    },
    {
      "id": 9,
      "title": "Resultado de pesquisa",
      "category": "Corporativo",
      "description": "Liquidificador",
      "cover_image": "projects\/9\/cover_image\/d8ba4e24616f1da4ab5f1bedb6a05c36.png",
      "date": "2023-01-01",
      "footage": "32",
      "location": "Rua de teste 123",
      "lat": "-1234567",
      "lng": "-3056789",
      "featured": false,
      "created_at": "2023-08-01T14:44:56.000000Z",
      "updated_at": "2023-08-01T14:44:57.000000Z",
      "members": [
        {
          "id": 5,
          "project_id": 9,
          "team_member_id": 1,
          "created_at": "2023-08-01T14:45:00.000000Z",
          "updated_at": "2023-08-01T14:45:00.000000Z",
          "member_info": {
            "id": 1,
            "profile_picture": "team_members\/1b5becebc4430e32db20d8fc0662013e8.png",
            "position": "Faxineiro Pleno",
            "name": "Pimpolho Rodrigo",
            "created_at": "2023-08-01T14:27:22.000000Z",
            "updated_at": "2023-08-01T14:27:23.000000Z"
          }
        },
        {
          "id": 6,
          "project_id": 9,
          "team_member_id": 2,
          "created_at": "2023-08-01T14:45:00.000000Z",
          "updated_at": "2023-08-01T14:45:00.000000Z",
          "member_info": {
            "id": 2,
            "profile_picture": "team_members\/26e558c4a90ba9b2b1356722c32c7ebfd.png",
            "position": "Removedor de Vinagrete de Hotdogs",
            "name": "Rafaelson Martinelson",
            "created_at": "2023-08-01T14:28:24.000000Z",
            "updated_at": "2023-08-01T14:28:25.000000Z"
          }
        }
      ],
      "images": [
        {
          "id": 13,
          "project_id": 9,
          "path": "projects\/9\/images\/c6ad6b06433feb4db1aab09873b9851b.png",
          "description": "descricao imagem 1",
          "order": 0,
          "type": "landscape",
          "created_at": "2023-08-01T14:44:58.000000Z",
          "updated_at": "2023-08-01T14:44:58.000000Z"
        },
        {
          "id": 14,
          "project_id": 9,
          "path": "projects\/9\/images\/ace4ad1eab2440e5b71e70a8f36fec14.png",
          "description": "descricao imagem 2",
          "order": 1,
          "type": "portrait",
          "created_at": "2023-08-01T14:44:59.000000Z",
          "updated_at": "2023-08-01T14:44:59.000000Z"
        }
      ]
    },
    {
      "id": 8,
      "title": "Resultado de pesquisa",
      "category": "Residencial",
      "description": "Liquidificador",
      "cover_image": "projects\/8\/cover_image\/a9c680feccf4a36700fb550973014772.png",
      "date": "2023-01-01",
      "footage": "32",
      "location": "Rua de teste 123",
      "lat": "-1234567",
      "lng": "-3056789",
      "featured": false,
      "created_at": "2023-08-01T14:44:44.000000Z",
      "updated_at": "2023-08-01T14:44:46.000000Z",
      "members": [
        {
          "id": 3,
          "project_id": 8,
          "team_member_id": 1,
          "created_at": "2023-08-01T14:44:48.000000Z",
          "updated_at": "2023-08-01T14:44:48.000000Z",
          "member_info": {
            "id": 1,
            "profile_picture": "team_members\/1b5becebc4430e32db20d8fc0662013e8.png",
            "position": "Faxineiro Pleno",
            "name": "Pimpolho Rodrigo",
            "created_at": "2023-08-01T14:27:22.000000Z",
            "updated_at": "2023-08-01T14:27:23.000000Z"
          }
        },
        {
          "id": 4,
          "project_id": 8,
          "team_member_id": 2,
          "created_at": "2023-08-01T14:44:49.000000Z",
          "updated_at": "2023-08-01T14:44:49.000000Z",
          "member_info": {
            "id": 2,
            "profile_picture": "team_members\/26e558c4a90ba9b2b1356722c32c7ebfd.png",
            "position": "Removedor de Vinagrete de Hotdogs",
            "name": "Rafaelson Martinelson",
            "created_at": "2023-08-01T14:28:24.000000Z",
            "updated_at": "2023-08-01T14:28:25.000000Z"
          }
        }
      ],
      "images": [
        {
          "id": 11,
          "project_id": 8,
          "path": "projects\/8\/images\/253055954b4b13c76281dcbcfdf7def6.png",
          "description": "descricao imagem 1",
          "order": 0,
          "type": "landscape",
          "created_at": "2023-08-01T14:44:47.000000Z",
          "updated_at": "2023-08-01T14:44:47.000000Z"
        },
        {
          "id": 12,
          "project_id": 8,
          "path": "projects\/8\/images\/504273db56d5a69e7894d09b836cd654.png",
          "description": "descricao imagem 2",
          "order": 1,
          "type": "portrait",
          "created_at": "2023-08-01T14:44:47.000000Z",
          "updated_at": "2023-08-01T14:44:48.000000Z"
        }
      ]
    },
    {
      "id": 7,
      "title": "titulo do projeto",
      "category": "Corporativo",
      "description": "descricao do projeto",
      "cover_image": "projects\/7\/cover_image\/1c52215f9efc3e9f04db75f5bf16551b.png",
      "date": "2023-01-01",
      "footage": "32",
      "location": "Rua de teste 123",
      "lat": "-1234567",
      "lng": "-3056789",
      "featured": true,
      "created_at": "2023-08-01T14:35:07.000000Z",
      "updated_at": "2023-08-01T14:35:09.000000Z",
      "members": [
        {
          "id": 1,
          "project_id": 7,
          "team_member_id": 1,
          "created_at": "2023-08-01T14:35:11.000000Z",
          "updated_at": "2023-08-01T14:35:11.000000Z",
          "member_info": {
            "id": 1,
            "profile_picture": "team_members\/1b5becebc4430e32db20d8fc0662013e8.png",
            "position": "Faxineiro Pleno",
            "name": "Pimpolho Rodrigo",
            "created_at": "2023-08-01T14:27:22.000000Z",
            "updated_at": "2023-08-01T14:27:23.000000Z"
          }
        },
        {
          "id": 2,
          "project_id": 7,
          "team_member_id": 2,
          "created_at": "2023-08-01T14:35:11.000000Z",
          "updated_at": "2023-08-01T14:35:11.000000Z",
          "member_info": {
            "id": 2,
            "profile_picture": "team_members\/26e558c4a90ba9b2b1356722c32c7ebfd.png",
            "position": "Removedor de Vinagrete de Hotdogs",
            "name": "Rafaelson Martinelson",
            "created_at": "2023-08-01T14:28:24.000000Z",
            "updated_at": "2023-08-01T14:28:25.000000Z"
          }
        }
      ],
      "images": [
        {
          "id": 9,
          "project_id": 7,
          "path": "projects\/7\/images\/184de3798f1b39910d4aa5e9770642ea.png",
          "description": "descricao imagem 1",
          "order": 0,
          "type": "landscape",
          "created_at": "2023-08-01T14:35:09.000000Z",
          "updated_at": "2023-08-01T14:35:09.000000Z"
        },
        {
          "id": 10,
          "project_id": 7,
          "path": "projects\/7\/images\/ec1ec3ff9a06dd0c0f95ff08e797b9ec.png",
          "description": "descricao imagem 2",
          "order": 1,
          "type": "portrait",
          "created_at": "2023-08-01T14:35:10.000000Z",
          "updated_at": "2023-08-01T14:35:10.000000Z"
        }
      ]
    },
    {
      "id": 6,
      "title": "titulo do projeto",
      "category": "Incorporação",
      "description": "descricao do projeto",
      "cover_image": "projects\/6\/cover_image\/eab4eed6236a5ed98f5eb2b34f6f7fa1.png",
      "date": "2023-01-01",
      "footage": "32",
      "location": "Rua de teste 123",
      "lat": "-1234567",
      "lng": "-3056789",
      "featured": true,
      "created_at": "2023-08-01T14:34:29.000000Z",
      "updated_at": "2023-08-01T14:34:31.000000Z",
      "members": [],
      "images": [
        {
          "id": 7,
          "project_id": 6,
          "path": "projects\/6\/images\/657f76346fb58dd185eb58e8d5500db0.png",
          "description": "descricao imagem 1",
          "order": 0,
          "type": "landscape",
          "created_at": "2023-08-01T14:34:32.000000Z",
          "updated_at": "2023-08-01T14:34:32.000000Z"
        },
        {
          "id": 8,
          "project_id": 6,
          "path": "projects\/6\/images\/e38b26e4cf726477aa7923fe194ea81e.png",
          "description": "descricao imagem 2",
          "order": 1,
          "type": "portrait",
          "created_at": "2023-08-01T14:34:32.000000Z",
          "updated_at": "2023-08-01T14:34:33.000000Z"
        }
      ]
    },
    {
      "id": 5,
      "title": "titulo do projeto",
      "category": "Corporativo",
      "description": "descricao do projeto",
      "cover_image": "projects\/5\/cover_image\/c73094c8576171ccf6303a8e11b53c11.png",
      "date": "2023-01-01",
      "footage": "32",
      "location": "Rua de teste 123",
      "lat": "-1234567",
      "lng": "-3056789",
      "featured": true,
      "created_at": "2023-08-01T14:06:12.000000Z",
      "updated_at": "2023-08-01T14:06:13.000000Z",
      "members": [],
      "images": [
        {
          "id": 5,
          "project_id": 5,
          "path": "projects\/5\/images\/7c540a5b363bd23babc1cd452ca128da.png",
          "description": "descricao imagem 1",
          "order": 0,
          "type": "landscape",
          "created_at": "2023-08-01T14:06:14.000000Z",
          "updated_at": "2023-08-01T14:06:14.000000Z"
        },
        {
          "id": 6,
          "project_id": 5,
          "path": "projects\/5\/images\/93e096100d918e11fb952a43616e464b.png",
          "description": "descricao imagem 2",
          "order": 1,
          "type": "portrait",
          "created_at": "2023-08-01T14:06:15.000000Z",
          "updated_at": "2023-08-01T14:06:15.000000Z"
        }
      ]
    }
  ],
  "first_page_url": "http:\/\/leburma-api-dev.phurshell.com\/api\/projects?page=1",
  "from": 1,
  "last_page": 1,
  "last_page_url": "http:\/\/leburma-api-dev.phurshell.com\/api\/projects?page=1",
  "links": [
    {
      "url": null,
      "label": "&laquo; Previous",
      "active": false
    },
    {
      "url": "http:\/\/leburma-api-dev.phurshell.com\/api\/projects?page=1",
      "label": "1",
      "active": true
    },
    {
      "url": null,
      "label": "Next &raquo;",
      "active": false
    }
  ],
  "next_page_url": null,
  "path": "http:\/\/leburma-api-dev.phurshell.com\/api\/projects",
  "per_page": 100,
  "prev_page_url": null,
  "to": 7,
  "total": 7,
  "status": true
}
