import IAdmin from '@/interfaces/IAdmin';
import IAdminUpdate from '@/interfaces/IAdminUpdate';
import IPasswordUpdate from '@/interfaces/IPasswordUpdate';
import FormMixinController from '@/mixins/FormMixinController';
import { isNameValid } from '@/utils/FormValidators';
import useVuelidate from '@vuelidate/core';
import { email, helpers, minLength, required, sameAs } from '@vuelidate/validators';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'AdminPasswordUpdate',
  props: {
    id: {
      type: String,
      default: ''
    }
  },
  setup() {
    return { v$: useVuelidate() }
  },
  data() {
    return {
      errorMessage: '',
      submitting: false,
      form: {
        new_password: '',
        old_password: '',
        passwordConfirmation: '',
      },
    };
  },
  validations() {
    return {
      form: {
        old_password: {
          required: helpers.withMessage('Informe uma senha com no mínimo 8 caracteres', required),
          minLength: helpers.withMessage('Informe uma senha com no mínimo 8 caracteres', minLength(8)),
        },
        new_password: {
          required: helpers.withMessage('Informe uma senha com no mínimo 8 caracteres', required),
          minLength: helpers.withMessage('Informe uma senha com no mínimo 8 caracteres', minLength(8)),
        },
        passwordConfirmation: {
          required: helpers.withMessage('As senhas são diferentes', required),
          sameAsPassword: helpers.withMessage('As senhas são diferentes', sameAs(this.form.new_password)),
        },
      }
    }
  },
  methods: {
    async handleSubmit() {
      try {
        this.submitting = true
        const params: IAdminUpdate = {
          old_password: this.form.old_password,
          password: this.form.new_password,
        }
        const res = await this.$userService.update(this.id, params)
        if (res.status) {
          this.$router.back()
          this.$eventBus.$emit('showToast', { title: 'Senha alterada', msg: `A senha do administrador alterada` })
        }
      } catch (e: any) {
        console.log(e)
        this.errorMessage = e.message
      } finally {
        this.submitting = false
      }
    },
  }
})