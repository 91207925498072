import DetailMixinController from '@/mixins/DetailMixinController'
import { store } from '@/store'
import { defineComponent } from 'vue'
export default defineComponent({
  mixins: [DetailMixinController],
  mounted() {
    this.hasDelete = String(store.state.session?.id) == this.id
    // this.hasDelete = false
  },
  methods: {
    prepareResponse(data: any) {
      return this.$userService.getFormattedUser(data)
    },
    fetchPromise() {
      return this.$userService.show(this.id)
    },
    deletePromise() {
      return this.$userService.delete(this.id)
    },
  }
})