<template>
  <div id="products">
    <Navbar current="Projetos" />
    <Sidebar />
    <Container>
      <Header :isSubHeader="false" title="Projetos" :iconClass="'fas fa-briefcase text-primary'"
        subtitle="Registro dos projetos" :totalCount="totalItemsRegistered">
        <template v-slot:button>
          <router-link v-slot="{ navigate }" :to="`${$paths.projects.add}`" class="btn lift btn-primary">
            <i class="fa-solid fa-circle-plus"></i>
            Novo projeto
          </router-link>
        </template>

      </Header>

      <div class="card">
        <SearchBar v-if="totalItemsRegistered > 0" @onSearch="search" />
        <LoadingContainer v-if="loadingItems" />
        <div v-else>
          <EmptyState v-if="!items.length" :totalItemsRegistered="totalItemsRegistered">
            <template v-slot:noItemsRegistered>
              <span class="fa-2x fas fa-folder-open text-black-50 mb-2"></span>
              <p>Nenhum projeto adicionado</p>
            </template>
          </EmptyState>
          <div v-else class="table-responsive">
            <table class="
                                              table table-sm table-nowrap
                                              card-table
                                              table-hover
                                              cursor-pointer
                                            ">
              <TableHead :columns="columns" v-on:sort="sort" />
              <tbody class="list">
                <router-link v-slot="{ navigate }" custom v-for="(item, index) in items" v-bind:key="'tr-' + index"
                  :to="`${$paths.projects.detail}/${item.id}`">
                  <tr @click="navigate">
                    <td>
                      <img class="cover-img rounded border" :src="item.formatted_cover_image" />
                      <span class="ps-3">{{ item.title }}</span>
                    </td>
                    <td>{{ item.category }}</td>
                    <td>{{ item.location }}</td>
                    <td class="text-end">
                      <time>{{ item.formatted_created_at }}</time>
                    </td>
                  </tr>
                </router-link>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="row" v-if="!loadingItems">
        <div class="col-12  text-end">
          <span class="text-muted">mostrando <b>{{ countItemsShowing }}</b> de
            <b>{{ countItemsTotal }}</b> resultado(s)</span>
        </div>
      </div>
      <div class="row justify-content-center" v-if="!loadingItems && moreItemsToLoad">
        <div class="col-2">
          <FormButton label="Carregar mais" :loading="loadingMoreItems" @onClick="loadMore" />
        </div>
      </div>
    </Container>
  </div>
</template>
<script>
import ProjectsController from './ProjectsController'
export default ProjectsController
</script>
<style scoped >
.cover-img {
  height: 60px;
  width: 90px;
}
</style>
