import IProject from '@/interfaces/IProject';
import { IProjectImage, ImageOrientation } from '@/interfaces/IProjectImage';
import FormMixinController from '@/mixins/FormMixinController';
import { isDateFuture } from '@/utils/FormValidators';
import { useVuelidate } from '@vuelidate/core';
import { helpers, minLength, required } from '@vuelidate/validators';
import { Modal } from 'bootstrap';
import { defineComponent } from 'vue';
import draggable from 'vuedraggable';
import AlbumImage from '../AlbumImage.vue';


export default defineComponent({
    name: 'AddProject',
    mixins: [FormMixinController],
    mounted() {
        this.getCategories()
        this.getMembers()
    },
    setup() {
        return { v$: useVuelidate() }
    },

    computed: {
        mapsCoordinates() {
            if (this.form.lat && this.form.lng) {
                return { lat: Number(this.form.lat), lng: Number(this.form.lng) }
            }
            return { lat: -23.58267193764547, lng: -46.68188665351167 }
        },
        featuredMessage() {
            return this.featured ? 'O projeto será exibido na pagina inicial do site' : 'O projeto não será exibido na pagina inicial do site'
        },
        featuredTitle() {
            return this.featured ? 'Exibir na pagina inicial' : 'Não exibir na pagina inicial'
        },
    },
    data() {
        return {
            imageOrientation: ImageOrientation.PORTRAIT,
            imageOptionsModal: null as any,
            redirectPath: `${this.$paths.projects.detail}`,
            existingCoverImage: '',
            coverImage: null as any,
            TEXT_LENGTH: 200,
            DESCRIPTION_LENGTH: 10000,
            categories: [] as any,
            members: [] as number[],
            album_images: [] as IProjectImage[],
            delete_images: [] as any[],
            form: {
                title: '',
                description: '',
                secondary_description: '',
                category: '',
                date: '',
                footage: '',
                // location: 'Taj Mahal',
                // lat: '27.175420179125247',
                // lng: '78.04222495987707',
                location: '',
                lat: '',
                lng: '',
                featured: 1,
                cover_image: {} as File,
                images: [] as File[],
                image_order: [],
                image_description: [],
                image_type: [],
                project_members: [] as number[]
            } as IProject,
            featured: true,
        }
    },

    components: {
        draggable,
        AlbumImage,
    },
    validations() {
        return {
            album_images: '',
            featured: '',
            form: {
                title: {
                    required: helpers.withMessage('Informe um título com no mínimo 5 letras', required),
                    minLength: helpers.withMessage('Informe um título com no mínimo 5 letras', minLength(5)),
                },
                category: {
                    required: helpers.withMessage('Informe uma categoria', required),
                },
                description: {
                    required: helpers.withMessage('Informe uma descrição com no mínimo 5 letras', required),
                },
                secondary_description: {
                    required: helpers.withMessage('Informe uma descrição secundária com no mínimo 5 letras', required),
                },
                date: {
                    required: helpers.withMessage('Informe uma data', required),
                    // dateIsFuture: helpers.withMessage('Informe uma data a partir de hoje',
                    //     function (value: string) {
                    //         return isDateFuture(value)
                    //     }),
                },
                location: {
                    required: helpers.withMessage('Informe a descrição da localização', required),
                },
                footage: {
                    required: helpers.withMessage('Informe a metragem', required),
                },
                lat: {
                    required: helpers.withMessage('Informe uma localizaçao no mapa', required),
                },
                lng: {
                    required: helpers.withMessage('Informe uma localizaçao no mapa', required),
                },
                featured: '',
                cover_image: this.isAdding ? { required: helpers.withMessage('Adicione uma imagem de capa', required), } : '',
                images: this.isAdding ? {
                    required: helpers.withMessage('Adicione ao menos uma imagem para o projeto', required),
                    minLength: helpers.withMessage('Adicione ao menos uma imagem para o projeto', minLength(1)),
                    $each: {
                        required
                    }
                } : '',
                project_members: this.isAdding ? {
                    required: helpers.withMessage('Escolha os membros do projeto', required),
                    minLength: helpers.withMessage('Escolha os membros do projeto', minLength(1)),
                    $each: {
                        required
                    }
                } : '',
            }
        }
    },
    methods: {
        async handleSubmit() {
            let values: IProject = { ...this.form }
            values.featured = Number(this.featured)
            if (this.isAdding) {
                this.form.image_order = this.album_images.map((r: IProjectImage, index: number) => (index + 1))
                return this.$projectsService.create(values)
            } else {
                let new_images_order = [] as number[]
                let existing_images_order = [] as any[]
                this.album_images.filter((elem: any) => elem).map((r: IProjectImage, index: number) => {
                    if (typeof r.id == 'string' && r.id.startsWith('new')) {
                        new_images_order.push(index + 1)
                    } else {
                        existing_images_order.push({ id: r.id, order: index + 1 })
                    }
                })

                values.image_order = new_images_order
                values.image_order_updates = JSON.stringify(existing_images_order)
                values.delete_images = this.delete_images

                return this.$projectsService.update(this.id, values)
            }
        },
        setupForm(d: any) {
            const data = this.$projectsService.getFormattedProject(d)
            const p: IProject = {
                title: data.title,
                description: data.description,
                secondary_description: data.secondary_description,
                category: data.category,
                date: data.date,
                location: data.location,
                footage: data.footage,
                lat: data.lat,
                lng: data.lng,
                featured: Number(data.featured),
                images: [],
                image_order: [],
                image_description: [],
                image_type: [],
                project_members: data.members.map((r: any) => r.team_member_id)
            }
            this.album_images = data.images.map((r: any) => {
                return {
                    id: r.id,
                    order: r.order,
                    url: r.formatted_path,
                    description: r.description,
                    type: r.type,
                }
            })
            this.existingCoverImage = data.formatted_cover_image
            this.featured = data.featured
            this.form = p
        },
        cropEnd(result: any) {
            this.coverImage = result
            this.form.cover_image = result.file
        },
        albumCropEnd(result: any) {
            const imageId = Math.floor(Math.random() * 9999999) + 1
            const item: IProjectImage = {
                id: `new_${imageId}`,
                image: result.file,
                order: 0,
                description: `album_image_${imageId}`,
                url: result.base64,
                type: this.imageOrientation
            }
            if (this.imageOrientation == ImageOrientation.LANDSCAPE || !this.album_images.length) {
                this.album_images.push(item)
            } else {
                let indexToAdd = -1
                this.album_images.map((r: IProjectImage, index: number) => {
                    const nextItem = this.album_images[index + 1]
                    if (r.type == ImageOrientation.PORTRAIT && nextItem?.type == ImageOrientation.LANDSCAPE) {
                        indexToAdd = index + 1
                    }
                })
                if (indexToAdd >= 0) {
                    this.album_images.splice(indexToAdd, 0, item);
                } else {
                    this.album_images.push(item)
                }
            }
            this.prepareFormImages()
        },
        fetchPromise() {
            return this.$projectsService.show(this.id)
        },
        // editImageClicked() {
        //     console.log(document.getElementById('member-edit-image-input'))
        //     document.getElementById('member-edit-image-input')?.click()
        // },
        setPlace(event: any) {
            const { geometry, formatted_address } = event
            this.form.location = formatted_address
            this.form.lat = `${geometry.location.lat()}`
            this.form.lng = `${geometry.location.lng()}`
        },
        memberSelected(member: any) {
            const index = this.form.project_members.findIndex((r: any) => r == member.id)
            if (index == -1) {
                this.form.project_members.push(member.id)
            } else {
                console.log('achou')
                this.form.project_members.splice(index, 1)
            }
        },
        handleAddImageClicked() {
            this.imageOptionsModal = new Modal(document.getElementById('imageOptionsModal') as any, {
                backdrop: 'static',
                keyboard: false
            })
            this.imageOptionsModal.toggle()
        },
        handleDeleteImageClicked($event: IProjectImage) {
            const index = this.album_images.findIndex(r => r.id == $event.id)
            this.album_images.splice(index, 1)
            this.prepareFormImages()
            if (!this.isAdding) {
                this.delete_images.push($event.id)
            }
        },
        addAlbumImageClicked() {
            this.imageOptionsModal.toggle()
            setTimeout(() => {
                document.getElementById('project-album-image-input')?.click()
            }, 700);
        },
        async getCategories() {
            try {
                const res = await this.$projectsService.listCategories()
                this.categories = res.data.map((elem: string) => {
                    return {
                        id: elem,
                        title: elem
                    }
                })
            } catch (e) {
                console.log(e)
            } finally {
            }
        },
        async getMembers() {
            try {
                const res = await this.$membersService.list({ order: 'ASC', sortName: 'name' })
                this.members = res.data.map((elem: any) => this.$membersService.getFormattedMember(elem))
            } catch (e) {
                console.log(e)
            } finally {
            }
        },
        prepareFormImages() {
            const imgs = this.album_images.filter((r: any) => `${r.id}`.startsWith('new'))
            this.form.images = imgs.map((r: IProjectImage) => r.image as File)
            this.form.image_description = imgs.map((r: IProjectImage) => r.description)
            this.form.image_type = imgs.map((r: IProjectImage) => r.type)
        },
        editImageClicked() {
            document.getElementById('project-cover-image-input')?.click()
        },

    }
})
