import ListControllerMixin from '@/mixins/ListControllerMixin'
import moment from 'moment'
import { defineComponent } from 'vue'

export default defineComponent({
    mixins: [ListControllerMixin],
    mounted() {
        this.loadingItems = true
        this.getData()
    },
    data() {
        return {
            columns: [
                {
                    name: 'Título',
                    sortName: null,
                    order: null
                },
                {
                    name: 'Categoria',
                    sortName: null,
                    order: null
                },
                {
                    name: 'Localização',
                    sortName: null,
                    order: null
                },
                {
                    name: 'Criado em',
                    sortName: null,
                    order: null
                }
            ],
            workingSites: [],
            selectedAdmin: ''
        }
    },
    methods: {
        prepareResponse(data: any) {
            data.map((elem: any) => {
                elem = this.$projectsService.getFormattedProject(elem)
            })
            return data
        },
        fetchPromise() {
            return this.$projectsService.list({
                ...this.filters,
                orderBy: this.columns.filter(r => r.order != null && r.sortName != null)
            })
        },
    }
})