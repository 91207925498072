import moment from 'moment';

export function isNameValid(value: string) {
    var ok = true
    var pattern = new RegExp('^(?=.*[a-zA-Z\u00C0-\u024F\u1E00-\u1EFF])([a-zA-Z\u00C0-\u024F\u1E00-\u1EFF]+)$');
    try {
        const fullName = value.trim().split(' ')
        if (fullName.length < 2) {
            return false
        }
        if (fullName[0].length < 2) return false
        fullName.map(r => {
            if (!pattern.test(r) || r.length < 2) {
                console.log(r, pattern.test(r))
                ok = false
            }
        })
        return ok
    } catch (e) {
        return false
    }
}
export function isDateFuture(value: string) {
    if (!value) return false
    try {
        const date = moment(value)
        if (!date.isValid()) return false
        return date.isSameOrAfter(moment(),'day')
    } catch (e) {
        return false
    }
}