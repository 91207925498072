
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'FormButton',
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: 'Field name'
    },
    form: {
      required: false,
      // type: {} as any,
      default: () => null as any
    },
  },
  computed: {
    fieldError() {
      if (!this.form) return
      let msg = ''
      try {
        const messages = this.form.$silentErrors?.map((r: any) => r.$message)
        msg = messages.length ? messages[0] : ''
      } catch (e) {
        console.log(e)
        msg = ''
      } finally {
        return msg
      }
    }
  },
  data() {
    return {
      showError: false
    }
  },
  methods: {
    clicked() {
      if (this.disabled) return
      this.$emit('onClick')
    },
    onMouseOver($event: any) {
      this.showError = true

    },
    onMouseLeave($event: any) {
      this.showError = false
    }
  }
})
