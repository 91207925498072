
import IAdmin from '@/interfaces/IAdmin'
import ILogin from '@/interfaces/ILogin'
import { MUTATIONS, store } from '@/store'
import moment from 'moment'
import { fakeStatistics } from '../fake_api'
import NetworkService, { Endpoint } from './NetworkService'
import IAdminUpdate from '@/interfaces/IAdminUpdate'
import IPasswordUpdate from '@/interfaces/IPasswordUpdate'

export default class UserService {
  network
  constructor() {
    this.network = new NetworkService()
  }
  list = (params: any) => {
    params.type = 'admin'
    return this.network.get(Endpoint.users, params)
  }
  create = (params: IAdmin) => {
    return this.network.post(Endpoint.users, params)
  }
  show = (id: string) => {
    return this.network.get(`${Endpoint.users}/${id}`)
  }
  delete = (id: string) => {
    return this.network.delete(`${Endpoint.users}/${id}`)
  }
  update = (id: string, params: IAdminUpdate) => {
    return this.network.put(`${Endpoint.users}/${id}`, params)
  }
  login = async (params: ILogin) => {
    const res = await this.network.post(Endpoint.login, params)
    if (['admin'].includes(res.data.type)) {
      return res
    } else {
      throw ({ status: false, message: 'Credenciais inválidas' })
    }
  }
  logout = () => {
    store.commit(MUTATIONS.CLEAR_SESSION)
  }
  listStatistics = () => {
    return Promise.resolve(fakeStatistics)
    // return this.network.get(this.network.endpoints.homeInfo)
  }
  getFormattedUser(data: any) {
    data.formatted_created_at = moment(data.created_at).format('DD/MM/YYYY')
    return data
  }
}
