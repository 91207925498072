import DetailMixinController from '@/mixins/DetailMixinController'
import AlbumImage from '../AlbumImage.vue';
import { defineComponent } from 'vue'

export default defineComponent({
  mixins: [DetailMixinController],
  mounted() {
    this.hasDelete = true
  },
  components: {
    AlbumImage
  },
  methods: {
    prepareResponse(data: any) {
      return this.$projectsService.getFormattedProject(data)
    },
    fetchPromise() {
      return this.$projectsService.show(this.id)
    },
    deletePromise() {
      return this.$projectsService.delete(this.id)
    },
  }
})