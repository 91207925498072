<template>
  <div>
    <Navbar :current="isAdding ? 'Novo Projeto' : 'Editar Projeto'" :link="$paths.projects.list" :linkName="'Projetos'" />
    <Sidebar />
    <Container>
      <Header :title="isAdding ? 'Novo Projeto' : 'Editar Projeto'" />
      <LoadingContainer v-if="loadingItems && !isAdding" />
      <template v-else>
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-12" v-if="isAdding">
                <label class="text-muted mb-3 mt-3 form-label">Imagem de capa <i class="text-danger">*</i></label>
              </div>
              <div class="col-12 mb-3" v-else>
                <label class="text-muted mb-3 mt-3 form-label">Imagem de capa</label>
                <div class="position-relative" style="height: 400px;">
                  <div style="right: -8px; bottom: -8px; z-index: 3" class="position-absolute">
                    <button @click="editImageClicked()" class="btn btn-primary rounded-circle"><span
                        class="fas fa-pencil"></span></button>
                  </div>
                  <img v-if="coverImage" :src="coverImage.base64" alt="..." class="rounded avatar avatar-xxl"
                    style="height:100%; width: 100%; object-fit: contain">
                  <img v-else :src="`${existingCoverImage}`" alt="..." class="rounded avatar avatar-xxl border"
                    style="height:100%; width: 100%; object-fit: contain">
                </div>
              </div>
              <ImageCropper modalId="project-cover-image" :accept="'.jpeg,.png,.jpg'" :customFileInputWidth="100"
                :aspectRatio="16 / 9" v-on:cropEnd="cropEnd" :hiddenInput="!isAdding"
                inputId="project-cover-image-input" />
            </div>
            <div class="row">
              <hr>
              <div class="col-12 col-md-12">
                <TextInput :required="true" label="Título do projeto" :maxLength="TEXT_LENGTH" :model="v$.form.title"
                  type="text" placeholder="Informe o título" :errorMsg="v$.form.title.required?.$message" />
              </div>
              <div class="col-12 col-md-6">
                <TextAreaInput :required="true" :rows="15" :label="'Descrição'" :maxLength="DESCRIPTION_LENGTH"
                  :model="v$.form.description" placeholder="Descrição do projeto"
                  :errorMsg="v$.form.description.required?.$message" />
              </div>
              <div class="col-12 col-md-6">
                <TextAreaInput :required="true" :rows="15" :label="'Descrição secundária'" :maxLength="DESCRIPTION_LENGTH"
                  :model="v$.form.secondary_description" placeholder="Descrição secundária do projeto"
                  :errorMsg="v$.form.secondary_description.required?.$message" />
              </div>
              <div class="col-12 col-md-12">
                <TextInput :required="true" label="Data" :model="v$.form.date" type="date" placeholder="Informe a data"
                  :errorMsg="v$.form.date.required?.$message" />
              </div>
              <div class="col-12 col-md-12">
                <TextInput :required="true" label="Metragem (somente números)" :mask="'#####'" :model="v$.form.footage"
                  type="text" placeholder="Informe a metragem" :errorMsg="v$.form.footage.required?.$message" />
              </div>
              <div class="col-12 col-md-12">
                <SelectInput label="Categoria" :model="v$.form.category" :options="categories" />
              </div>
              <hr>
              <div class="col-12 col-md-12">
                <div>
                  <label class="text-muted mt-3 form-label">Localização<i class="text-danger">*</i></label>
                </div>
                <GMapAutocomplete placeholder="Pesquise por uma localização..." class="form-control mb-3"
                  @place_changed="setPlace">
                </GMapAutocomplete>
                <div v-if="mapsCoordinates">
                  <GMapMap :center="mapsCoordinates" style="height: 450px; width: 100%;" :zoom="15" :draggable="false">
                    <GMapCluster :zoomOnClick="false">
                      <GMapMarker :key="1" :clickable="false" :draggable="false" :position="mapsCoordinates" />
                    </GMapCluster>
                  </GMapMap>
                </div>
              </div>
              <div class="col-12 col-md-12 mt-3">
                <TextInput :required="true" label="Descrição customizada da localização" :model="v$.form.location"
                  type="text" placeholder="Informe a descrição" :errorMsg="v$.form.location.required?.$message" />
              </div>
              <hr>
              <div class="col-12 col-md-12">
                <div class="card">
                  <div class="card-body">
                    <div class="row">
                      <div class="col-auto" :class="{ 'opacity-50': !featured }">
                        <span class="fas fa-star fa-medium-size text-primary"></span>
                      </div>
                      <div class="col ps-0" :class="{ 'opacity-50': !featured }">
                        <h3 class="mb-0">{{ featuredTitle }}</h3>
                        <span>{{ featuredMessage }}</span>
                      </div>
                      <div class="col-auto d-flex align-items-center">
                        <div class="form-check form-switch">
                          <input class="form-check-input" v-model="v$.featured.$model" type="checkbox"
                            id="featuredCheckbox" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12">
                <hr>
                <label class="text-muted mt-3 mb-5 form-label">Membros<i class="text-danger">*</i></label>
                <div class="list-group list-group-flush my-n3 members-container rounded border">
                  <div v-for="(  member, index  ) in members" class="list-group-item p-2 cursor-pointer"
                    :class="{ 'bg-selected': form.project_members.includes(member.id) }" @click="memberSelected(member)">
                    <div class="row align-items-center">
                      <div class="col-auto">
                        <a href="profile-posts.html" class="avatar">
                          <img :src="member.formatted_profile_picture" alt="..." class="avatar-img rounded-circle">
                        </a>
                      </div>
                      <div class="col ms-n2">
                        <h4 class="mb-1">
                          <span>{{ member.name }}</span>
                        </h4>
                      </div>
                      <div class="col-auto pe-5">
                        <span v-if="form.project_members.includes(member.id)" class="fas fa-check text-primary"></span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <hr class="mt-5">
                <label class="text-muted mt-3 form-label">Imagens<i class="text-danger">*</i></label>
                <p class="text-muted">
                  <small>Selecione imagens para o album do projeto. Você tem a opção de adicionar imagens no formato
                    Retrato
                    ou Paisagem.<br>À medida que você for
                    adicionando as imagens ao projeto, elas serão exibidas abaixo em uma
                    disposição semelhante à do site, como um preview do resultado final.<br>
                    Você tambêm pode reordená-las arrastando para a posição desejada.
                  </small>
                </p>
                <button class="btn btn-outline-primary mt-2" @click="handleAddImageClicked">
                  <span class="fas fa-image"></span>
                  Adicionar imagem
                </button>
              </div>
            </div>
            <div class="row mt-4">
              <div class="col-8 offset-2">
                <draggable v-model="album_images" item-key="description" class="row">
                  <template #item="{ element, $event }">
                    <AlbumImage :image="element" class="cursor-grab"
                      @onDeleteClicked="handleDeleteImageClicked($event)" />
                  </template>
                </draggable>
              </div>
            </div>
            <div class="row">
              <div class="col-12 text-center">
                <span class="text-danger text-center">{{ errorMessage }}</span>
              </div>
            </div>
            <div class="row mt-5" id="bottom-btn-container">
              <div class="col-12 text-center">
                <FormButton :label="'Salvar'" :disabled="v$.$invalid" :form="v$.form" :loading="submitting"
                  @onClick="submit" />
              </div>
            </div>
          </div>
        </div>
        <ImageCropper v-if="imageOrientation == 'portrait'" :accept="'.jpeg,.png,.jpg'" :customFileInputWidth="100"
          :aspectRatio="1" v-on:cropEnd="albumCropEnd" modalId="project-album-image" inputId="project-album-image-input"
          :hiddenInput="true" />
        <ImageCropper v-if="imageOrientation == 'landscape'" :accept="'.jpeg,.png,.jpg'" :customFileInputWidth="100"
          :aspectRatio="16 / 9" v-on:cropEnd="albumCropEnd" modalId="project-album-image"
          inputId="project-album-image-input" :hiddenInput="true" />
        <ModalComponent title="Escolha a orientação da imagem que deseja adicionar." id="imageOptionsModal"
          class="modal-lg">
          <template v-slot:body>
            <div class="row">
              <div class="col-6">
                <p class="mb-2">Retrato</p>
                <div class="position-relative" @click="imageOrientation = 'portrait'">
                  <span v-if="imageOrientation == 'portrait'"
                    class="fas fa-check-circle fa-2x text-primary position-absolute opacity-75 orientation-icon"></span>
                  <img src="@/assets/img/portrait_example.png" class="orientation-example-image rounded cursor-pointer"
                    :class="{ 'selected': imageOrientation == 'portrait' }" alt="">
                </div>
              </div>
              <div class="col-6">
                <p class="mb-2">Paisagem</p>
                <div class="position-relative" @click="imageOrientation = 'landscape'">
                  <span v-if="imageOrientation == 'landscape'"
                    class="fas fa-check-circle fa-2x text-primary position-absolute opacity-75 orientation-icon"></span>
                  <img src="@/assets/img/landscape_example.png" class="orientation-example-image rounded cursor-pointer"
                    :class="{ 'selected': imageOrientation == 'landscape' }" alt="">
                </div>
              </div>
            </div>
          </template>
          <template v-slot:footer>
            <button type="button" @click="addAlbumImageClicked" class="btn btn-primary">
              Escolher imagem
            </button>
          </template>
        </ModalComponent>
      </template>
    </Container>
  </div>
</template>
<script>
import ProjectAddController from './ProjectAddController'
export default ProjectAddController
</script>
<style scoped>
.members-container {
  overflow-y: scroll;
  max-height: 390px;
}

.list-group-item:hover {
  background-color: #5b816818;
}

.bg-selected {
  background-color: #5b81680d;
}

.album-img-project {
  height: 310px;
  width: 100%
}

img.orientation-example-image {
  border: 2px dashed #d7d7d7;
  padding: 4px;
}

img.orientation-example-image.selected {
  border: 2px solid #5b8168;
}

.orientation-icon {
  top: 20px;
  right: 20px;
}

#bottom-btn-container {
  /* position: fixed;
  width: 75%;
  z-index: 100;
  bottom: 0;
  background: white; */
}
</style>
