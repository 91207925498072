<template>
  <div>
    <Navbar :current="isAdding ? 'Adicionar Membro' : 'Editar Membro'" :link="$paths.members.list"
      :linkName="'Membros'" />
    <Sidebar />
    <Container>
      <Header :title="isAdding ? 'Adicionar Membro' : 'Editar Membro'" />
      <div class="card">
        <div class="card-body">
          <div class="row">
            <div class="col-12" v-if="isAdding">
              <label class="text-muted mb-3 mt-3 form-label">Foto de perfil <i class="text-danger">*</i></label>
              <ImageCropper :accept="'image/*'" :customFileInputWidth="100" :aspectRatio="1" v-on:cropEnd="cropEnd" />
            </div>
            <div class="col-12 mb-3" v-else>
              <label class="text-muted mb-3 mt-3 form-label">Foto de perfil</label>
              <div class="w-15 position-relative" style="width:150px; height:150px">
                <div style="right: -8px; bottom: -8px; z-index: 3" class="position-absolute">
                  <button @click="editImageClicked()" class="btn btn-primary rounded-circle"><span
                      class="fas fa-pencil"></span></button>
                </div>
                <img v-if="profilePicture" :src="profilePicture.base64" alt="..." class="rounded-circle avatar avatar-xxl"
                  style="height:100%; width: 100%; object-fit: fill">
                <img v-else :src="`${existingImage}`" alt="..." class="rounded-circle avatar avatar-xxl border"
                  style="height:100%; width: 100%; object-fit: fill">
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-md-12">
              <TextInput :required="true" label="Nome" :maxLength="TEXT_LENGTH" :model="v$.form.name" type="text"
                placeholder="Informe o nome" :errorMsg="v$.form.name.required?.$message" />
            </div>
            <div class="col-12 col-md-12">
              <TextInput :required="true" label="Cargo" :maxLength="TEXT_LENGTH" :model="v$.form.position" type="text"
                placeholder="Informe o cargo" :errorMsg="v$.form.position.required?.$message" />
            </div>
          </div>
          <div class="row">
            <div class="col-12 text-center">
              <span class="text-danger text-center">{{ errorMessage }}</span>
            </div>
          </div>
          <FormButton :label="'Salvar'" :loading="submitting" :disabled="v$.$invalid" :form="v$.form" @onClick="submit" />
        </div>
      </div>
      <ImageCropper :accept="'image/*'" :customFileInputWidth="100" :aspectRatio="1" v-on:cropEnd="cropEnd"
        inputId="member-edit-image-input" :hiddenInput="true" />
    </Container>
  </div>
</template>
<script>
import MemberAddController from './MemberAddController'
export default MemberAddController
</script>
<style scoped></style>
