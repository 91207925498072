
import { createStore, Store, useStore } from "vuex"
import { InjectionKey } from "vue"
import INotification from "@/interfaces/INotification"
import ISession from '@/interfaces/ISession'
interface State {
  session: ISession | null
  notifications: INotification[]
  token: string | null
}
export const key: InjectionKey<Store<State>> = Symbol()
export function customUseStore(): Store<State> {
  return useStore(key)
}

export enum MUTATIONS {
  SAVE_SESSION = 'SAVE_SESSION',
  SAVE_TOKEN = 'SAVE_TOKEN',
  ADD_NOTIFICATION = 'ADD_NOTIFICATION',
  CLEAR_SESSION = 'CLEAR_SESSION'
}

export function getSessionFromStorage(): ISession | null {
  const localSession = localStorage.getItem(process.env.VUE_APP_API_SESSION_KEY)
  if (!localSession) return null
  const s: any = JSON.parse(localSession)
  const session: ISession = {
    id: s.id,
    name: s.name,
    email: s.email,
    created_at: s.created_at,
    type: s.type
  }
  return session
}
export function getTokenFromStorage(): string | null {
  return localStorage.getItem(process.env.VUE_APP_API_TOKEN_KEY)
}

export const store = createStore<State>({
  state: {
    session: getSessionFromStorage(),
    notifications: [],
    token: getTokenFromStorage()
  },
  mutations: {
    [MUTATIONS.SAVE_SESSION](state, session: ISession) {
      state.session = session
      localStorage.setItem(process.env.VUE_APP_API_SESSION_KEY, JSON.stringify(session))
    },
    [MUTATIONS.SAVE_TOKEN](state, token: string) {
      state.token = token
      localStorage.setItem(process.env.VUE_APP_API_TOKEN_KEY, token)
    },
    [MUTATIONS.CLEAR_SESSION](state) {
      localStorage.removeItem(process.env.VUE_APP_API_SESSION_KEY)
      localStorage.removeItem(process.env.VUE_APP_API_TOKEN_KEY)
      state.session = null
      state.token = null
    },
    [MUTATIONS.ADD_NOTIFICATION](state, notification: INotification) {
      notification.id = new Date().getTime()
      state.notifications.push(notification)
      setTimeout(() => {
        state.notifications = state.notifications.filter((not) => not.id != notification.id)
      }, 3000)
    },
  },
})










// export default createStore({
//   state: {
//     userSession: null,//JSON.parse(localStorage.getItem(process.env.VUE_APP_API_SESSION_KEY)),
//     token: null,
//     userInfo: {
//     }
//   },
//   getters: {
//   },
//   mutations: {
//     saveSession(state, newSession) {
//       state.userSession = newSession
//       localStorage.setItem(process.env.VUE_APP_API_SESSION_KEY, JSON.stringify(newSession))
//     },
//     saveToken(state, token) {
//       state.token = token
//       localStorage.setItem(process.env.VUE_APP_API_TOKEN_KEY, token)
//     },
//     clearSession(state) {
//       state.userSession = null
//       state.token = null
//       localStorage.removeItem('token')
//       localStorage.removeItem(process.env.VUE_APP_API_SESSION_KEY)
//     },
//     setUserInfo(state, data) {
//       const userInfo = {
//         ...state.userInfo,
//         ...data
//       }
//       state.userInfo = userInfo
//       // localStorage.setItem(process.env.VUE_APP_USER_INFO_KEY, JSON.stringify(userInfo))
//     }
//   },
//   actions: {
//     syncSessionWithApi: async ({ commit }, { token } = { token: null }) => {

//     },
//     saveSession: async ({ commit }, data) => {
//       console.log('session', data)
//       commit('saveSession', data.data)
//       commit('saveToken', data.access_token)
//     },
//     saveUserInfo: async ({ commit }, data) => {
//       commit('setUserInfo', data)
//     }
//   },
//   modules: {
//   }
// })
