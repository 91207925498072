<template>
  <div id="password_update">
    <Navbar current="Alterar senha" :link="$paths.admins.list" :sublink="`${$paths.admins.detail}/${id}`"
      :linkName="type == 'administradores' ? 'Administradores' : 'Especialistas'"
      :sublinkName="type == 'administradores' ? 'Detalhes do administrador' : 'Detalhes do especialista'" />
    <Sidebar />
    <Container>
      <Header title="Alterar Senha" />
      <div class="card">
        <div class="card-body">
          <div class="row">
            <form>
              <PasswordInput label="Senha antiga" :model="v$.form.old_password" placeholder="Informe a senha antiga"
                :errorMsg="v$.form.old_password.required.$message" />
              <PasswordInput label="Nova senha" :model="v$.form.new_password" placeholder="Informe a nova senha"
                :errorMsg="v$.form.new_password.required.$message" />
              <PasswordInput label="Confirme a senha" :model="v$.form.passwordConfirmation"
                placeholder="Confirme a nova senha" :errorMsg="v$.form.passwordConfirmation.required.$message" />

              <div class="row">
                <div class="col-12 text-center">
                  <span class="text-danger text-center">{{ errorMessage }}</span>
                </div>
              </div>
              <FormButton label="Alterar senha" :loading="submitting" :disabled="v$.$invalid" :form="v$.form"
                @onClick="handleSubmit" />
            </form>
          </div>
        </div>
      </div>
    </Container>
  </div>
</template>
<script>
import AdminPasswordUpdateController from "./AdminPasswordUpdateController";
import Header from "../../../components/Header.vue";
export default AdminPasswordUpdateController;
</script>
<style scoped></style>
