import DetailMixinController from '@/mixins/DetailMixinController'
import { defineComponent } from 'vue'

export default defineComponent({
  mixins: [DetailMixinController],
  mounted() {
    this.hasDelete = true
  },
  methods: {
    prepareResponse(data: any) {
      return this.$membersService.getFormattedMember(data)
    },
    fetchPromise() {
      return this.$membersService.show(this.id)
    },
    deletePromise() {
      return this.$membersService.delete(this.id)
    },
  }
})