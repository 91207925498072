<template>
  <ConfirmationAlert />
  <NotificationToast />
  <router-view />
</template>
<script>
import NotificationToast from '@/components/NotificationToast'
import { defineComponent } from 'vue'
export default defineComponent({

  created() {
    document.title = 'Leburma | CMS'
  },
  components: {
    NotificationToast
  }

})
</script>
