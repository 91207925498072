<template>
  <div id="add-admin">
    <Navbar :current="isAdding ? 'Adicionar Administrador' : 'Editar Administrador'" :link="$paths.admins.list"
      :linkName="'Administradores'" />
    <Sidebar />
    <Container>
      <Header :title="isAdding ? 'Adicionar Administrador' : 'Editar Administrador'" />
      <div class="card">
        <div class="card-body">
          <h3>Informações</h3>
          <div class="row">
            <div class="col-12 col-md-6" :class="{ 'col-md-12': !isAdding }">
              <TextInput label="Nome" :required="true" :model="v$.form.name" :maxLength="30" type="text"
                placeholder="Informe um nome" :errorMsg="v$.form.name.required?.$message" />
            </div>
            <div class="col-12 col-md-6" v-if="isAdding">
              <TextInput label="Email" :required="true" :model="v$.form.email" type="email" :maxLength="50"
                placeholder="nome@email.com" :errorMsg="v$.form.email.required?.$message" />
            </div>
            <div class="col-12 col-md-6" v-if="isAdding">
              <PasswordInput label="Senha" :required="true" :model="v$.form.password" :maxLength="30"
                placeholder="Informe uma senha" :errorMsg="v$.form.password.required?.$message" />
            </div>
            <div class="col-12 col-md-6" v-if="isAdding">
              <PasswordInput label="Confirme a senha" :required="true" :maxLength="30"
                :model="v$.form.passwordConfirmation" placeholder="Confirme a senha"
                :errorMsg="v$.form.passwordConfirmation.required?.$message" />
            </div>
          </div>
          <form>
            <div class="row">
              <div class="col-12 text-center">
                <span class="text-danger text-center">{{ errorMessage }}</span>
              </div>
            </div>
            <FormButton label="Adicionar" :loading="submitting" :disabled="v$.$invalid" :form="v$.form"
              @onClick="submit" />
          </form>
        </div>
      </div>
    </Container>
  </div>
</template>
<script>
import AddAdminsController from "./AddAdminsController";
export default AddAdminsController;
</script>
<style scoped></style>
