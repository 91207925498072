const paths = {
    not_found: '/not-found',
    login: '/login',
    home: '/projetos',
    projects: {
        list: '/projetos',
        detail: '/projetos/detalhes',
        add: '/projetos/adicionar',
        edit: '/projetos/editar',
    },
    members: {
        list: '/membros',
        detail: '/membros/detalhes',
        add: '/membros/adicionar',
        edit: '/membros/editar',
    },
    admins: {
        list: '/administradores',
        add: '/administradores/adicionar',
        edit: '/administradores/editar',
        detail: '/administradores/detalhes',
        password_update: '/editar-senha/administradores',
    },
    myAccount: '/minha-conta'
}

export default paths