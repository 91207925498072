import IMember from '@/interfaces/IMember'
import FormMixinController from '@/mixins/FormMixinController'
import { isNameValid } from '@/utils/FormValidators'
import { useVuelidate } from '@vuelidate/core'
import { helpers, minLength, required } from '@vuelidate/validators'
import { defineComponent } from 'vue'
export default defineComponent({
    name: 'AddMember',
    mixins: [FormMixinController],
    setup() {
        return { v$: useVuelidate() }
    },
    mounted() {
        this.redirectPath = `${this.$paths.members.detail}`
    },
    data() {
        return {

            existingImage: '',
            profilePicture: null as any,
            TEXT_LENGTH: 50,
            form: {
                name: '',
                position: '',
                profile_picture: null
            } as IMember,
        }
    },
    validations() {
        return {
            form: {
                name: {
                    required: helpers.withMessage('Informe um nome e um sobrenome validos', required),
                    valid_name: helpers.withMessage('Informe um nome e um sobrenome validos',
                        function (value: string) {
                            return isNameValid(value)
                        })
                },
                position: {
                    required: helpers.withMessage('Informe um cargo', required),
                    minLength: helpers.withMessage('Informe um cargo', minLength(5)),
                },
                profile_picture: this.isAdding ? { required: helpers.withMessage('Adicione uma imagem de perfil', required) } : '',
            }
        }
    },
    methods: {
        async handleSubmit() {
            if (this.isAdding) {
                return this.$membersService.create(this.form)
            } else {
                return this.$membersService.update(this.id, this.form)
            }
        },
        setupForm(data: any) {
            const f: IMember = {
                name: data.name,
                position: data.position,
            }
            const m = this.$membersService.getFormattedMember(data)
            this.existingImage = m.formatted_profile_picture
            this.form = f
        },
        cropEnd(result: any) {
            this.profilePicture = result
            this.form.profile_picture = result.file
        },
        fetchPromise() {
            return this.$membersService.show(this.id)
        },
        editImageClicked() {
            document.getElementById('member-edit-image-input')?.click()
        },
    }
})
