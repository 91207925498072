import ModalComponent from "@/components/ModalComponent";
import { dataURLtoFile } from '@/utils/Helper';
import { Modal } from 'bootstrap';
import "cropperjs/dist/cropper.css";
import VueCropper from "vue-cropperjs";

// export interface ICropResult {
//     file: File
//     base64: String
// }

export default {
    props: {
        accept: {
            type: String,
            default: 'image/*,.pdf'
        },
        fileType: {
            type: String,
            default: 'image'
        },
        customFileInputWidth: {
            type: Number,
            default: 724,
        },
        aspectRatio: {
            type: Number,
            default: 1,
        },
        inputId: {
            type: String,
            default: `def-${new Date().getTime()}`,
        },
        hiddenInput: {
            type: Boolean,
            default: false,
        },
        modalId: {
            type: String,
            default: `${new Date().getTime()}`,
        },

    },
    computed: {
        modal_id() {
            return `image-modal-${this.modalId}`
        },
        input_name() {
            return `${this.inputId}-name`
        },
        cropper_ref() {
            return `${this.modalId}-cropper`
        },

    },
    mounted() {
        this.$eventBus.$on("clearImageCropper", () => {
            this.imgSrc = null
            this.cropImg = ""
            this.fileImage = ''
            this.title = ''
            this.fileName = ''
        });
    },
    created() {
    },
    data() {
        return {
            imgSrc: null,
            cropImg: "",
            fileImage: '',
            title: '',
            percentage: 0,
            uploading: false,
            counter: null,
            fileName: '',
            eventLabel: 'Selecione a imagem'
        }
    },
    components: {
        VueCropper,
        ModalComponent
    },
    methods: {
        cropImage() {
            // get image data for post processing, e.g. upload or setting image src
            this.cropImg = this.$refs[this.cropper_ref].getCroppedCanvas().toDataURL();
            this.fileImage = dataURLtoFile(this.$refs[this.cropper_ref].getCroppedCanvas().toDataURL(), 'text.png')
            this.imageModal.toggle()
            this.upload()
        },
        setImage(e) {
            const file = e.target.files[0];
            this.fileName = file.name
            if (file.type.indexOf('image') !== -1) {
                this.imageModal = new Modal(document.getElementById(this.modal_id), {
                    backdrop: 'static',
                    keyboard: false
                })
                this.imageModal.toggle()
                if (file.type.indexOf("image/") === -1) {
                    alert("Por favor selecione uma imagem");
                    return;
                }
                if (typeof FileReader === "function") {
                    const reader = new FileReader();
                    reader.onload = (event) => {
                        this.imgSrc = event.target.result;
                        // rebuild cropperjs with the updated source
                        this.$refs[this.cropper_ref].replace(event.target.result);
                    };
                    reader.readAsDataURL(file);
                } else {
                    alert("Sorry, FileReader API not supported");
                }
            } else {
                // this.imgSrc = '@assets/img/pdf_placeholder.png'; // imagem do pdf placeholder
                this.fileImage = file
                this.cropImg = '@/assets/img/pdf_placeholder.png'
                this.upload()
            }
        },
        upload() {
            this.uploading = true
            let timer = Math.floor(Math.random() * (500)) + 500;
            this.percentage = 100
            timer = 1
            this.counter = setTimeout(() => {
                this.setCounter()
            }, timer)
        },
        cancelUpload() {
            this.uploading = false
            this.percentage = 0
            this.cropImg = ''
            clearTimeout(this.counter)
        },
        setCounter() {
            if (this.percentage < 100) {
                let max = 100
                let p = Math.floor(Math.random() * (max - this.percentage + 1)) + this.percentage;
                this.percentage = p >= 100 ? 100 : p
                this.upload()
            } else {
                this.uploading = false
                this.percentage = 0
                clearTimeout(this.counter)
                let result = { file: this.fileImage, base64: this.cropImg }
                this.$emit('cropEnd', result)
                this.cropClosed()
            }
        },
        cropClosed() {
            if (this.$refs[this.input_name]) {
                this.$refs[this.input_name].value = ''
            }
        },
    }
}