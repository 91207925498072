<template>
  <div id="mission_detail">
    <Navbar :current="item?.title" :link="$paths.projects.list" :linkName="'Projetos'" />
    <Sidebar />
    <Container>
      <Header :title="item?.title">
        <template v-slot:button>
          <button v-if="hasDelete" @click="handleDelete" class="btn btn-outline-primary ms-3">
            Remover
            <i class="fa-solid fa-trash-alt me-1"></i>
          </button>
          <router-link :to="`${$paths.projects.edit}/${id}`" tag="a" class="btn btn-primary lift ms-3">
            Editar
            <i class="fa-solid fa-pen me-1"></i>
          </router-link>
        </template>
      </Header>
      <LoadingContainer v-if="loadingItems" />
      <div v-else>
        <div class="row">
          <div class="col-12 col-xl-12">
            <div class="card">
              <div class="card-body">
                <div class="row">
                  <div class="col-12">
                    <img class="rounded cover-img border" style="object-fit: cover;" :src="item.formatted_cover_image" />
                  </div>
                </div>
                <hr>
                <div class="row">
                  <h3 class="mt-5">Informações</h3>
                  <div class="col-12 col-xl-7">
                    <TextInput :readOnly="true" label="Título" :value="item.title" />
                  </div>
                  <div class="col-12 col-xl-5">
                    <TextInput :readOnly="true" label="Categoria" :value="item.category" />
                  </div>
                  <div class="col-12 col-xl-5">
                    <TextInput :readOnly="true" label="Mostrando na home" :value="`${item.formatted_featured}`" />
                  </div>
                  <div class="col-12 col-xl-7">
                    <TextInput :readOnly="true" label="Metragem" :value="`${item.footage}m²`" />
                  </div>
                  <div class="col-12 col-xl-6">
                    <TextInput :readOnly="true" label="Data de inicio" :value="item.formatted_date" />
                  </div>
                  <div class="col-12 col-xl-6">
                    <TextInput :readOnly="true" label="Criado em" :value="item.formatted_created_at" />
                  </div>
                  <div class="col-12 col-xl-6">
                    <TextAreaInput :rows="15" :readOnly="true" :label="'Descrição'" :value="item.description" />
                  </div>
                  <div class="col-12 col-xl-6">
                    <TextAreaInput :rows="15" :readOnly="true" :label="'Descrição secundária'"
                      :value="item.secondary_description" />
                  </div>
                </div>
                <hr>
                <div class="row">
                  <h3 class="mt-5 mb">Localização</h3>
                  <div class="col-12">
                    <p class="">{{ item.location }}</p>
                    <template v-if="item.lat && item.lng">
                      <GMapMap :center="item.formatted_coords" style="height: 450px; width: 100%;" :zoom="15"
                        :draggable="false">
                        <GMapCluster :zoomOnClick="false">
                          <GMapMarker :key="1" :clickable="false" :draggable="false" :position="item.formatted_coords" />
                        </GMapCluster>
                      </GMapMap>
                    </template>
                  </div>
                </div>
                <hr>
                <div class="row">
                  <h3 class="mt-5">Imagens</h3>
                  <div class="col-8 offset-2">
                    <div class="row">
                      <template v-for="(  img, index  ) of item.images">
                        <AlbumImage :image="{ ...img, url: img.formatted_path }" :canDelete="false" />
                      </template>
                    </div>
                  </div>
                </div>
                <hr>
                <div class="row">
                  <h3 class="mt-5">Membros</h3>
                  <div class="col-12">
                    <div class="list-group list-group-flush my-n3">
                      <div v-for="(  member, index  ) in   item.members  " class="list-group-item">
                        <router-link v-slot="{ navigate }" :to="`${$paths.members.detail}/${member.team_member_id}`">
                          <div class="row align-items-center">
                            <div class="col-auto">
                              <a href="profile-posts.html" class="avatar">
                                <img :src="member.member_info.formatted_profile_picture" alt="..."
                                  class="avatar-img rounded-circle">
                              </a>
                            </div>
                            <div class="col ms-n2">
                              <h4 class="mb-1">
                                <span>{{ member.member_info.name }}</span>
                              </h4>
                            </div>
                          </div>
                        </router-link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Container>
  </div>
</template>
<script>
import ProjectDetailController from "./ProjectDetailController"
export default ProjectDetailController
</script>
<style>
.album-img {
  height: 200px;
}

.cover-img {
  height: 500px;
  width: 100%;
  object-fit: cover;
}
</style>
