<template>
  <div id="admins">
    <Navbar current="Administradores" />
    <Sidebar />
    <Container col="col-12">
      <Header title="Administradores" :isSubHeader="false" subtitle="Registro de administradores"
        :iconClass="'fa-solid fa-user-cog text-primary'" :totalCount="items.length" :btnLink="$paths.admins.add"
        btnLabel="Adicionar" btnIcon="fa-solid fa-circle-plus" />
      <div class="card">
        <SearchBar v-if="items.length > 0" @onSearch="search" />
        <LoadingContainer v-if="loadingItems" />
        <div v-else>
          <EmptyState v-if="!items.length" :totalItemsRegistered="items.length">
            <template v-slot:noItemsRegistered>
              <span class="fa-3x fa-solid fa-user-cog text-black-50 mb-2"></span>
              <p>Nenhum admin criado</p>
            </template>
          </EmptyState>
          <div v-else class="table-responsive">
            <table ref="myTable" class="table table-sm table-nowrap card-table table-hover cursor-pointer">
              <TableHead :columns="columns" v-on:sort="sort" />
              <tbody class="list">
                <router-link v-slot="{ navigate }" custom v-for="(item, index) in items" v-bind:key="'tr-' + index"
                  :to="`${$paths.admins.detail}/${item.id}`">
                  <tr @click="navigate">
                    <td>{{ item.name }}</td>
                    <td>{{ item.email }}</td>
                    <td class="text-end">
                      <time>{{ item.formatted_created_at }}</time>
                    </td>
                  </tr>
                </router-link>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="row" v-if="!loadingItems">
        <div class="col-12 text-end">
          <span class="text-muted">mostrando <b>{{ countItemsShowing }}</b> de
            <b>{{ countItemsTotal }}</b> resultado(s)</span>
        </div>
      </div>
      <div class="row justify-content-center" v-if="!loadingItems && moreItemsToLoad">
        <div class="col-2">
          <FormButton label="Carregar mais" :loading="loadingMoreItems" @onClick="loadMore" />
        </div>
      </div>
    </Container>
  </div>
</template>
<script>
import AdminsController from "./AdminsController";
export default AdminsController;
</script>
