
import { IProjectImage } from '@/interfaces/IProjectImage';
import { defineComponent } from 'vue';
export default defineComponent({
    name: 'Navbar',
    props: {
        image: {
            required: true,
            type: Object as () => IProjectImage
        },
        canDelete: {
            type: Boolean,
            default: true
        },
    },
    methods: {
        handleDeleteImage() {
            this.$emit('onDeleteClicked', { ...this.image })
        }
    }
})
