import IApiResponse from '@/services/NetworkService'
import useVuelidate from '@vuelidate/core'
import { defineComponent } from 'vue'

export default defineComponent({
    name: 'FormMixinController',
    props: {
        id: {
            type: String,
            default: ""
        },
    },
    computed: {
        isAdding() {
            return !this.id
        },
    },
    mounted() {
        if (!this.isAdding) {
            this.getData()
        }
    },
    data() {
        return {
            redirectPath: '',
            errorMessage: '',
            submitting: false,
            loadingItems: true,
            fieldError: ''
        }
    },
    methods: {
        async getData() {
            try {
                const res: any = await this.fetchPromise()
                this.setupForm(res.data)
            } catch (e) {
                console.log(e)
            } finally {
                this.loadingItems = false
            }
        },
        setupForm(data: any) {
            return data
        },
        fetchPromise() {
            throw new Error('Método fetchPromise não implementado')
        },
        async submit() {
            try {
                this.submitting = true
                const res: any = await this.handleSubmit()
                console.log('resposta da api', res)
                if (res.status) {
                    if (this.isAdding) {
                        this.$eventBus.$emit('showToast', { title: 'Item criado', msg: `Item criado com sucesso` })
                        console.log(`aeeeeeeeeee ${this.redirectPath}/${res.data.id}`)
                        this.$router.push(`${this.redirectPath}/${res.data.id}`)
                    } else {
                        console.log(`aeeeeeeeeee  ${this.redirectPath}/${this.id}`)
                        this.$eventBus.$emit('showToast', { title: 'Item alterado', msg: `Item alterado com sucesso` })
                        this.$router.push(`${this.redirectPath}/${this.id}`)
                    }
                }
            } catch (e: any) {
                console.log(e)
                this.errorMessage = e.message
            } finally {
                this.submitting = false
            }
        },
        handleSubmit() { },
    }
})