import { defineComponent } from 'vue'

export interface IDeleteItemConf {
    confirmationMessage: string,
    notificationTitle: string,
    notificationMessage: string,
}

export default defineComponent({
    name: 'DetailMixinController',
    props: {
        id: {
            type: String,
            default: ""
        },
    },
    mounted() {
        this.loadingItems = true
        this.getData()
    },
    data() {
        return {
            hasDelete: false,
            item: {} as any,
            loadingItems: false,
            loadingDelete: false,
            deleteConf: {
                confirmationMessage: 'Deseja excluir este item permanentemente?',
                notificationTitle: 'Item excluído com sucesso',
                notificationMessage: 'O item foi removido',
            } as IDeleteItemConf
        }
    },
    methods: {
        async getData() {
            try {
                const res: any = await this.fetchPromise()
                let data = this.prepareResponse(res.data)
                this.item = data
            } catch (e) {
                console.log(e)
            } finally {
                this.loadingItems = false
            }
        },
        /**
         * @return faz as mudanças na resposta e retorna pro getData 
         * -fazer o override na tela pra tratar a resposta conforme necessario
         */
        prepareResponse(data: any) {
            return data
        },
        deletePromise() { },
        fetchPromise() {
            throw new Error('Método fetchPromise não implementado')
        },
        handleDelete() {
            if (!this.hasDelete) return
            this.$eventBus.$emit("showConfirmationAlert", {
                message: this.deleteConf.confirmationMessage,
                confirmCallback: async () => {
                    try {
                        this.loadingDelete = true
                        await this.deletePromise()
                        this.$eventBus.$emit('showToast', { title: this.deleteConf.notificationTitle, msg: this.deleteConf.notificationMessage })
                        this.$router.back()
                    } catch (e) {
                        console.log(e);
                    } finally {
                        this.loadingDelete = false
                    }
                },
            });
        },
    }
})