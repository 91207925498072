
import ILogin from '@/interfaces/ILogin'
import ISession from '@/interfaces/ISession'
import { MUTATIONS } from '@/store'
import { useVuelidate } from '@vuelidate/core'
import { email, required } from '@vuelidate/validators'
import { defineComponent } from 'vue'
import { useStore } from 'vuex'
export default defineComponent({
  name: 'Login',
  setup() {
    const store = useStore()
    return {
      v$: useVuelidate(),
      store
    }
  },
  mounted() {
    this.$userService.logout()
  },
  data() {
    return {
      errorMessage: '',
      submitting: false,
      form: {
        email: '',
        password: ''
      }
    }
  },
  validations: {
    form: {
      email: {
        required,
        email
      },
      password: {
        required
      }
    }
  },
  methods: {
    async login() {
      this.errorMessage = ''
      this.submitting = true
      const params: ILogin = { email: this.form.email, password: this.form.password }
      try {
        const res = await this.$userService.login(params)
        console.log('aqui esta a res', res)
        const session: ISession = {
          id: res.data.id,
          name: res.data.name,
          email: res.data.email,
          created_at: res.data.created_at,
          type: res.data.type
        }
        this.store.commit(MUTATIONS.SAVE_SESSION, session)
        this.store.commit(MUTATIONS.SAVE_TOKEN, res.access_token)
        this.$router.push(this.$paths.home)
      }
      catch (e: any) {
        console.log(e)
        this.errorMessage = e.message
      } finally {
        this.submitting = false
      }
    }
  }
})
