import IAdmin from '@/interfaces/IAdmin';
import IAdminUpdate from '@/interfaces/IAdminUpdate';
import FormMixinController from '@/mixins/FormMixinController';
import useVuelidate from '@vuelidate/core';
import { email, helpers, minLength, required, sameAs } from '@vuelidate/validators';
import { defineComponent } from 'vue';
export default defineComponent({
  name: 'AddAdmins',
  mixins: [FormMixinController],
  setup() {
    return { v$: useVuelidate() }
  },
  mounted() {
    this.redirectPath = `${this.$paths.admins.detail}`
  },
  data() {
    return {
      errorMessage: '',
      submitting: false,
      form: {
        name: '',
        email: '',
        password: '',
        passwordConfirmation: '',
      },
    };
  },
  validations() {
    return {
      form: {
        name: {
          required: helpers.withMessage('Informe um nome e um sobrenome validos', required),
        },
        email: this.isAdding ? {
          required: helpers.withMessage('Informe um email valido', required),
          email: helpers.withMessage('Informe um email valido', email),
        } : '',
        password: this.isAdding ? {
          required: helpers.withMessage('Informe uma senha com no mínimo 8 caracteres', required),
          minLength: helpers.withMessage('Informe uma senha com no mínimo 8 caracteres', minLength(8)),
        } : '',
        passwordConfirmation: this.isAdding ? {
          required: helpers.withMessage('As senhas são diferentes', required),
          sameAs: helpers.withMessage('As senhas são diferentes', sameAs(this.form.password)),
        } : '',
      }
    }
  },
  methods: {
    async handleSubmit() {
      if (this.isAdding) {
        const values: IAdmin = {
          name: this.form.name,
          email: this.form.email,
          password: this.form.password,
          type: 'admin'
        }
        return this.$userService.create(values)
      } else {
        const values: IAdminUpdate = { name: this.form.name }
        return this.$userService.update(this.id, values)
      }

    },

    async fetchPromise() {
      return this.$userService.show(this.id)
    },
    async setupForm(data: any) {
      this.form.name = data.name
    },
  }
})