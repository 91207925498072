import ListControllerMixin from '@/mixins/ListControllerMixin';
import { defineComponent } from 'vue';

export default defineComponent({
  mixins: [ListControllerMixin],
  mounted() {
    this.loadingItems = true
    this.getData()
  },
  data() {
    return {
      columns: [
        {
          name: 'Nome',
          sortName: null,
          order: null
        },
        {
          name: 'Email',
          sortName: null,
          order: null
        },
        {
          name: 'Data de criação',
          sortName: null,
          order: null
        },
      ],
    };
  },
  methods: {
    fetchPromise() {
      return this.$userService.list({
        ...this.filters,
        orderBy: this.columns.filter(r => r.order != null && r.sortName != null)
      })
    },
    handleDetail(id: string, index: number) {
      this.$router.push({ path: `${this.$paths.admins.detail}/${id}` });
    },
    prepareResponse(data: any) {
      data.map((d: any) => {
        return this.$userService.getFormattedUser(d)
      })
      return data
    }
  }
})
